<template>
  <div class="website-container">
    <div class="head">
      <img src="../assets/img/sofiya.svg" alt="索菲娅" class="sofiya-icon">
      <div class="title">伊利亚</div>
    </div>
    
    <div class="slogan-box"> 
      <div class="slogan">安静 专注 构建美</div>
    </div>

    <div class="introduction-box"> 
      <div class="introduction">Lets goal, Gamify your life</div>
    </div>

    <div class="imageshow-box">
      <img :src="selectedImage" alt="展示图片" class="display-icon">
    </div>

    <div class="thumbnail-box">
      <img 
        v-for="(image, index) in images" 
        :key="index" 
        :src="image" 
        alt="缩略图" 
        class="thumbnail-icon" 
        :style="{ opacity: selectedImage === image ? 1 : 0.7 }" 
        @click="selectImage(image)"
      >
    </div>

    <div class="download-container">
      <div class="downloadtitle">下载 伊利亚 客户端版本</div>
      <img src="../assets/img/sofiya.svg" alt="下载图片" class="sofiya-icon1">
      <div class="downloadbtn-box">
        <button class="Macdownload-btn" @click="setVersion('1')">Windows</button>
        <button class="Windowsdownload-btn" @click="setVersion('2')">MacOS</button>
      </div>
    </div> 
    
  </div>

  <div class="bottom">
    <div class="bottom-box">
      <div class="email">123456789@qq.com</div>
      <div class="QQgroup">QQ 群</div>
      <div class="QQgroup">123456789</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; // 导入 axios
export default {
  name: 'HomePageComponent',
  props: {
    msg: String
  },
  data() {
    return {
      // 使用 require 来确保路径正确
      images: [
        require('../assets/img/imgshow1.svg'),
        require('../assets/img/imgshow2.svg'),
        require('../assets/img/imgshow3.svg'),
        require('../assets/img/imgshow4.svg'),
        require('../assets/img/imgshow5.svg'),
        require('../assets/img/imgshow6.svg')
      ],
      selectedImage: require('../assets/img/imgshow1.svg'), // 使用 require 定义默认显示的图片
      version: null // 初始化 version 变量
    };
  },
  methods: {
    selectImage(image) {
      this.selectedImage = image; // 更新展示的图片
    },
    setVersion(type) {
      this.version = type; // 设置 version 变量为 1 或 2
      this.download(); // 调用 download 方法
    },
    async download() {
      try {
        const token="eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjZiZmQ5ZDhmLTdjNWUtNDdlMy05MWE3LWQxZGFlMTUzNDgxZSJ9.dx4n7-P1aQDZ3aduboZPNkCYudKxZ4MqRaQpNgU7ht9u6TWFBvby6XS2itZVSaSzLV1WhRVmFZTUU05JTMlHbw"; // 替换为你的token
        const headers = {
          Authorization: `Bearer ${token}`, // 替换为你的 token
        };

        const response = await axios.get('https://api.eliya.fun/user/download', {
          headers,
          params: {
            version: this.version // 将 version 作为查询参数
          }
        });
        console.log(response.data)
        
        if (response.data) {
          window.open(response.data, '_blank'); // 在新窗口打开链接
        } else {
          console.error('未获取到下载链接');
        }
      } catch (error) {
        console.error('下载请求失败:', error);
      }
    },
  }
}
</script>

<style scoped>
.website-container {
  background-color: #1a043a; /* 背景颜色 */
  width: 100%; /* 宽度占满 */
  height: 220vh; /* 高度占满视口 */
  margin: 0; /* 去除默认外边距 */
  padding: 0; /* 去除默认内边距 */
  display: flex; /* 使用 flexbox 布局 */
  flex-direction: column; /* 垂直方向排列子元素 */
}

.head {
  display: flex;
  justify-content: center; /* 水平居中 */
  margin-top: 20vh; /* 使用视口高度 */
}

.sofiya-icon {
  width: 5vw; /* 以视口宽度为单位 */
  height: auto; /* 高度自适应 */
}
.title {
  font-size: 3vw; /* 以视口宽度为单位 */
  font-weight: 600;
  margin-top: 1vh;
  margin-left: 1vw;
  color: #FFFFFF;
}
.slogan-box {
  display: flex;
  justify-content: center; /* 水平居中 */
  margin-top: 10vh; /* 使用视口高度 */
}
.slogan {
  font-size: 5vw; /* 以视口宽度为单位 */
  font-weight: 600;
  color: #FFFFFF;
}
.introduction-box {
  display: flex;
  justify-content: center; /* 水平居中 */
  margin-top: 3vh; /* 使用视口高度 */
}
.introduction {
  font-size: 2vw; /* 以视口宽度为单位 */
  font-weight: 600;
  color: #FFFFFF;
}

.imageshow-box {
  display: flex;
  justify-content: center; /* 水平居中 */
  margin-top: 15vh; /* 使用视口高度 */
}

.display-icon {
  width: 60vw; /* 以视口宽度为单位 */
  height: auto; /* 高度自适应 */
  filter: brightness(1); /* 设置图片亮度 */
}

.thumbnail-box {
  display: flex;
  justify-content: center; /* 水平居中 */
  margin-top: 2vh; /* 使用视口高度 */
}

.thumbnail-icon {
  width: 8vw; /* 以视口宽度为单位 */
  height: auto; /* 高度自适应 */
  margin: 0 0.5vw; /* 缩略图间距 */
  cursor: pointer; /* 鼠标悬停时为指针 */
  transition: opacity 0.3s; /* 添加过渡效果 */
}

.thumbnail-icon:hover {
  opacity: 1; /* 鼠标悬停时增加不透明度 */
}
.download-container {
  width: 58vw; /* 以视口宽度为单位 */
  height: auto; /* 高度自适应 */
  background-color: #5CA9F9;
  display: flex; /* 使用flex布局 */
  flex-direction: column; /* 纵向排列 */
  align-items: center; /* 水平居中对齐 */
  margin: 5vh auto; /* 使用视口高度 */
  border-radius: 16px;
  padding: 2vh; /* 内边距 */
  margin-top: 20vh;
}

.downloadtitle {
  font-size: 3vw; /* 以视口宽度为单位 */
  font-weight: 600;
  color: #FFFFFF;
  margin-top: 5vh; /* 使用视口高度 */
}
.sofiya-icon1 {
  width: 10vw; /* 以视口宽度为单位 */
  height: auto; /* 高度自适应 */
  margin-top: 4vh; /* 使用视口高度 */
}
.downloadbtn-box {
  margin: 4vh 0; /* 使用视口高度 */
}
.Macdownload-btn,
.Windowsdownload-btn {
  width: 8vw; /* 以视口宽度为单位 */
  height: 6vh; /* 使用视口高度 */
  border-radius: 8px;
  border: none;
  margin: 0 2vw; /* 缩略图间距 */
  font-size: 1.2vw; /* 以视口宽度为单位 */
  font-weight: 600;
  color: #000000;
  letter-spacing: 1px;
  cursor: pointer;
  transition: box-shadow 0.3s; /* 添加过渡效果 */
}

.Macdownload-btn:hover,
.Windowsdownload-btn:hover {
  box-shadow: 0 4px 20px rgba(250, 249, 249, 0.3); /* 设置阴影效果 */
}
.bottom {
  width: 100%; /* 宽度占满 */
  height: 50vh; /* 高度自适应 */
  background-color: #FFFFFF; /* 背景颜色为白色 */
  margin-top: auto; /* 自动向下填充，确保它在最底部 */
  padding: 5vh 0; /* 使用视口高度 */
  display: flex;
  flex-direction: column;
  align-items: center; /* 水平居中对齐 */
}
.bottom-box {
  display: flex;
  justify-content: center; /* 水平居中 */
  margin-top: 40vh;
}
.email {
  font-size: 1vw; /* 以视口宽度为单位 */
  font-weight: 400;
  margin-right: 5vw; /* 缩略图间距 */
}
.QQgroup {
  font-size: 1vw; /* 以视口宽度为单位 */
  font-weight: 400;
  margin-right: 0.5vw; /* 缩略图间距 */
}
</style>
