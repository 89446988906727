<template>
  <div>
    <Homepage :msg="message" /> <!-- 将 message 传递给 homepage 组件 -->
  </div>
</template>

<script>
import Homepage from './components/homepage.vue'; // 导入 homepage 组件

export default {
  name: 'ExampleComponent',
  components: {
    Homepage // 注册 homepage 组件
  },
  data() {
    return {
      message: '欢迎使用 Figma EX' // 定义要传递的 msg 值
    };
  }
}
</script>

<style>

</style>
